@import url('https://fonts.cdnfonts.com/css/hvdtrial-brandon-grotesque');

body {
  margin: 0;
  font-family: 'HvDTrial Brandon Grotesque', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  font-family: 'HvDTrial Brandon Grotesque', sans-serif;
}

h1,
h2,
h3 {
  font-weight: 600 !important;
  margin: 0 !important;
  padding: 0 !important;
}

p {
  margin: 0 !important;
  padding: 0 !important;
}

a,
a:visited,
a:hover,
a:active,
a:link {
  text-decoration: none !important;
  /* color: inherit !important; */
}

:root {
  --white: #fff;
  --blue: #007bff;
  --dark-blue: #1a387c;
  --purple: #6f42c1;
  --red: #dc3545;
  --yellow: #ffc107;
  --green: #28a745;
  --secondary: #6ab175;
  --primary: #1a387c;

  --gray: #c6c6c6;
}

.App {
  max-width: 600px;
  margin: 0 auto;
}

.blue-bg {
  background-image: url('./images/3.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  height: auto;
  margin-top: -0.1rem;
}

.very-bold-text {
  font-weight: 900;
}

.image-formatter {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-object-fit {
  object-fit: contain;
}

.custom-button {
  display: inline-block;
  border: none;
  border-radius: 6px; /* Set the border radius for a rounded shape */
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  width: 100%;
  font-size: 17px;
  background: linear-gradient(
    90deg,
    #f5e9aa -1%,
    #d4ae51 -0.99%,
    #f5e9aa 35.71%,
    #fbf4b6 38.77%,
    #fbf4b6 57.13%,
    #fbf4b6 73.45%,
    #d4ae51 100.97%,
    #f5e9aa 100.98%
  );
  height: 50px;
}

.custom-button2 {
  display: inline-block;
  border: none;
  border-radius: 6px; /* Set the border radius for a rounded shape */
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  width: 100%;
  font-size: 17px;
  background: blue; /* Set background to none for transparency */
  color: #f5e9aa; /* Set text color to #f5e9aa */
  -webkit-background-clip: text; /* Clip the background to the text */
  background-clip: text;
  -webkit-text-fill-color: #f5e9aa; /* Hide the text color */
  height: 40px;
  border-width: 1.2px;
  border-style: solid;
}

.custom-button-gray {
  display: inline-block;
  border: none;
  border-radius: 15px; /* Set the border radius for a rounded shape */
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  width: 100%;
  font-size: 17px;
  background: #c6c6c6;
  height: 50px;
}

.ant-modal-content {
  padding: 0px !important;
  border-radius: 10px !important;
}

.ant-form-item .ant-form-item-label {
  padding: 0px !important;
}

.ant-form-item {
  margin-bottom: 10px !important;
}

.footer-bg {
  background: linear-gradient(
    90deg,
    #dccc81 1.97%,
    #fdf7d5 35.79%,
    #dccc81 100%
  );
}

.smallest-text {
  font-size: 10px;
}

.sm-text {
  font-size: 12.5px;
}

.smaller-text {
  font-size: 11px;
}

.ant-form-item .ant-form-item-label > label {
  color: #fff;
}
.ant-checkbox-wrapper {
  color: #fff;
}

/* .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #26d175 !important;
} */

.ant-input {
  padding: 11px 11px;
  width: 100%;
}

.ant-checkbox-wrapper {
  line-height: 30px;
  font-size: 12px;
}

.ant-checkbox + span {
  line-height: 1rem;
}

.ant-select {
  height: 45px !important;
  text-align: left;
}

.ant-select-selector {
  height: 45px !important;
}

@media screen and (max-width: 600px) {
  .smallest-text {
    font-size: 9px;
  }
}

@media only screen and (min-width: 600px) {
  .normal-text {
    font-size: 16px;
  }

  .sm-text {
    font-size: 14px;
  }
  .smaller-text {
    font-size: 13px;
  }
}

@media only screen and (min-width: 830px) {
  .smallest-text {
    font-size: 13px;
  }
  .sm-text {
    font-size: 14px;
  }
  .smaller-text {
    font-size: 13px;
  }
}
